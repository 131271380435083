
import { Buttons } from "@/types/BasicComponent";
import { nextTick, reactive, defineComponent, ref, computed } from "vue";
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import { getOrderPayProcess } from "@/api/invoice";
import { clearObject } from "@/utils/common";

export default defineComponent({
  components: {
    BasicFormDialog
  },
  setup() {
    const modalShow = ref(false)

    const orderId = ref(-1)
    const data = ref<AnyObject>({})
    const goodsData = computed(() => data.value.goodsList || [])
    const payProcessData = computed(() => data.value.details || [])
    const show = async (id: number) => {
      clearObject(data.value)

      orderId.value = id

      const resp = await getOrderPayProcess([id])
      const respData = resp.data.data
      if (Array.isArray(respData) && respData.length > 0) data.value = respData[0]

      modalShow.value = true
    }

    const getCalcMode = (calcMode: number) => {
      const calcUnit = getCalcUnit(calcMode)
      switch(calcMode) {
        case 1: return `体积（${calcUnit}）`
        case 2: return `重量（${calcUnit}）`
        case 3: return `数量（${calcUnit}）`
        default: return ''
      }
    }
    const getCalcUnit = (calcMode: number) => {
      switch(calcMode) {
        case 1: return '立方'
        case 2: return '吨'
        case 3: return '件'
        default: return ''
      }
    }

    const footerButtons = reactive<Buttons>([
      {
        type: 'default',
        label: '关闭',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      modalShow,
      footerButtons,
      getCalcMode,
      getCalcUnit,
      show,
      data,
      goodsData,
      payProcessData
    }
  }
})
