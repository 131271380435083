import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "开票信息回填",
    footerButtons: _ctx.footerButtons,
    width: "1300px",
    "label-width": "125px",
    top: "7vh",
    closeOnClickModal: true
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_form, {
        class: "dialog-form",
        model: _ctx.formData,
        ref: "formRef",
        "label-width": "130px",
        rules: _ctx.formRule,
        inline: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "invoiceCode",
            size: "small",
            label: "发票号"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.invoiceCode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.invoiceCode) = $event)),
                style: {"width":"100%"}
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "invoiceDate",
            size: "small",
            label: "开票日期"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_date_picker, {
                modelValue: _ctx.formData.invoiceDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.invoiceDate) = $event)),
                clearable: false
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "invoiceUser",
            size: "small",
            label: "开票人"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.invoiceUser,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.invoiceUser) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "amountMoney",
            size: "small",
            label: "开票金额(元)"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.amountMoney,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.amountMoney) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue", "footerButtons"]))
}