import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f536a03c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "支付路径详情",
    footerButtons: _ctx.footerButtons,
    width: "1200px",
    "label-width": "125px",
    top: "7vh",
    closeOnClickModal: true
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_timeline, { style: {"width":"90%"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  class: "description-item",
                  column: 4,
                  border: "",
                  title: "订单信息"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 订单号 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.data.orderCode), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 胶合板厂 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.data.jhbcName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 生产机主 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.data.xqjzName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 开票总额 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.data.invoiceAmount) + " 元 ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, { span: "3" }, {
                      label: _withCtx(() => [
                        _createTextVNode(" 货物信息 ")
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_descriptions, {
                          class: "margin-top",
                          column: 4,
                          direction: "vertical"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsData, (goods, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: goods.gid
                              }, [
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物名称",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.goodsName), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"]),
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物描述",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.unitDesc), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"]),
                                _createVNode(_component_el_descriptions_item, {
                                  label: "计价单位",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.getCalcMode(goods.countMode)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"]),
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物数量",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.volume + _ctx.getCalcUnit(goods.countMode)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"])
                              ], 64))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  class: "margin-top",
                  column: 5,
                  direction: "vertical",
                  border: "",
                  title: "支付路径"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payProcessData, (payProcess, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        _createVNode(_component_el_descriptions_item, {
                          label: "序号",
                          "label-class-name": index == 0? '': 'hidden'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(index + 1), 1)
                          ]),
                          _: 2
                        }, 1032, ["label-class-name"]),
                        _createVNode(_component_el_descriptions_item, {
                          label: "银行账户",
                          "label-class-name": index == 0? '': 'hidden'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(payProcess.bankAccountName), 1)
                          ]),
                          _: 2
                        }, 1032, ["label-class-name"]),
                        _createVNode(_component_el_descriptions_item, {
                          label: "银行卡号",
                          "label-class-name": index == 0? '': 'hidden'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(payProcess.bankAccountNo), 1)
                          ]),
                          _: 2
                        }, 1032, ["label-class-name"]),
                        _createVNode(_component_el_descriptions_item, {
                          label: "货款（元）",
                          "label-class-name": index == 0? '': 'hidden'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(payProcess.goodsFee), 1)
                          ]),
                          _: 2
                        }, 1032, ["label-class-name"]),
                        _createVNode(_component_el_descriptions_item, {
                          label: "服务费（元）",
                          "label-class-name": index == 0? '': 'hidden'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(payProcess.serviceFee), 1)
                          ]),
                          _: 2
                        }, 1032, ["label-class-name"])
                      ], 64))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "footerButtons"]))
}