
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import { Buttons } from "@/types/BasicComponent";
import { defineComponent, reactive, ref, toRef } from "vue";
import { ElMessage } from "element-plus";
import { calcOrderInvoiceAmount, InvoiceBackfillData, orderInvoiceBackfill } from "@/api/invoice";
import dayjs from 'dayjs'
import { useStore } from "vuex"
import { FormRulesMap } from "element-plus/lib/components/form/src/form.type";

export default defineComponent({
  components: {
    BasicFormDialog,
  },
  emits: ['on-success'],
  setup(props, { emit }) {

    const store = useStore()

    const modalShow = ref(false)
    const formData = reactive<InvoiceBackfillData>({
      invoiceCode: '',
      invoiceDate: dayjs().startOf('day').toDate(),
      invoiceUser: store.state.user.nick,
      amountMoney: 0,
      orderInvoiceIds: []
    })

    const formRule: FormRulesMap = {
      invoice_Code: [
        { required: true, message: '发票号不能为空' }
      ],
      invoice_Date: [
        { required: true, message: '开票日期不能为空' }
      ],
      invoice_User: [
        { required: true, message: '开票人不能为空' }
      ],
      amount_Money: [
        { required: true, message: '开票金额不能为空' }
      ]
    }

    const dataList = ref<AnyArray>([])

    const show = async (selectedList: AnyArray) => {
      dataList.value = selectedList
      formData.orderInvoiceIds = selectedList.map(s => s.oiid)

      const orderIds = selectedList.map(s => s.orderid)
      const calcResp = await calcOrderInvoiceAmount(orderIds)
      formData.amountMoney = calcResp.data.data

      formData.invoiceDate =  dayjs().startOf('day').toDate()
      modalShow.value = true
    }

    const formRef = ref()

    const footerButtons = reactive<Buttons>([
      {
        type: 'primary',
        label: '确定',
        // show: () => parentActiveTabPane.value === TabPane.CHECKED,
        onClick: async () => {
          await formRef.value.validate()
          await orderInvoiceBackfill(formData)
          ElMessage.success('发票回填成功')
          emit('on-success')
          modalShow.value = false
        }
      },  {
        type: 'default',
        label: '关闭',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      formData,
      modalShow,
      footerButtons,
      formRule,
      formRef,
      show
    }
  }
})
