
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onActivated, onMounted, reactive, ref, toRef } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, SpanMethod, TableColumns } from '@/types/BasicComponent'
import { copyObject, formatDate, formatTime, getAreaListOfGx, getTableColumnsSummary } from '@/utils/common'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { findInvoiceApplyPage, getOrderPayProcess, InvoiceApplyPageQueryParams, QueryOrderInvoiceListParams } from '@/api/invoice'
import PayProcessDetail from '@/views/finance/PayProcessDetail.vue'
import InvoiceFillBackDialog from '@/views/finance/InvoiceFillBackDialog.vue'
import { ElMessage } from 'element-plus'
import { getPlatformBankAccountList } from '@/api/pay'
import * as math from "mathjs"

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    PayProcessDetail,
    InvoiceFillBackDialog
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<InvoiceApplyPageQueryParams>({})
    const tableData = ref<AnyArray>([])
    const badgeValues = reactive<AnyObject>({})
    const gxAreaList = reactive<AnyArray>([])
    const bpaInfoList = reactive<AnyArray>([])

    const selectedTableData = ref<AnyArray>([])
    const selectCount = computed(() => {
      let seldata = selectedTableData.value
      let selcount = 0
      for(let ix = 0; ix < seldata.length; ix++){
        if(seldata[ix].orderid){
          selcount++
        }
      }
      return selcount
    })

    const payProcessData = ref<AnyArray>([])

    const getTableData = async () => {
      if (searchData.bpaIds && searchData.bpaIds.length > 0) {
        searchData.bpaIdsStr = searchData.bpaIds.join(',')
      } else {
        searchData.bpaIdsStr = undefined
      }
      const res = await findInvoiceApplyPage(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      const respData = res.data.data.records
      const resultData: AnyArray = []

      if (respData.length > 0) {
        const payProcessResp = await getOrderPayProcess(respData.map((rd: AnyObject) => rd.orderid).filter((id: number) => id != null))
        payProcessData.value = payProcessResp.data.data
      }

      const buildMultiColumns = (orderObj: AnyObject, goodsInfo?: AnyObject, payProcessInfo?: AnyObject) => {
        // 货物信息
        if (goodsInfo && (typeof goodsInfo === 'object')) {
          orderObj.goodsName = goodsInfo.categoryName + goodsInfo.goodsName
          const countMode = goodsInfo.countMode
          if (countMode === 1) {
            orderObj.goodsAmount = goodsInfo.volume
            orderObj.goodsUnit = '立方米'
          } else if (countMode === 2) {
            orderObj.goodsAmount = goodsInfo.weight
            orderObj.goodsUnit = '吨'
          } else if (countMode === 3) {
            orderObj.goodsAmount = goodsInfo.quantity
            orderObj.goodsUnit = '件'
          }
          orderObj.unitPrice = goodsInfo.unitPrice
          orderObj.goodsTotalPrice = goodsInfo.totalPrice
          orderObj.unitDesc = goodsInfo.unitDesc
        }

        // 支付路径
        if (payProcessInfo && (typeof payProcessInfo === 'object')) {
          if (payProcessInfo.bankAccountName){
            orderObj.bankAccountName = payProcessInfo.bankAccountName
          }
          if (payProcessInfo.goodsFee){
            orderObj.goodsFee = payProcessInfo.goodsFee
          }
          if (payProcessInfo.serviceFee){
            orderObj.serviceFee = payProcessInfo.serviceFee
          }
          if (payProcessInfo.invoiceAmount){
            orderObj.invoiceAmount = payProcessInfo.invoiceAmount
          } else if (payProcessInfo.goodsFee && payProcessInfo.serviceFee) {
            orderObj.invoiceAmount = math.chain(math.bignumber(payProcessInfo.goodsFee)).add(math.bignumber(payProcessInfo.serviceFee)).done() + ''
          }
        }

        return orderObj
      }

      for (let i = 0; i < respData.length; i++) {
        const td = respData[i];
        const goodsList = td.goodsList

        const payProcessListMatch = payProcessData.value.filter(pp => pp.orderCode == td.orderCode)[0]?.details || []

        td.index = pagination.pageOffset + i
        if (goodsList.length === 0) resultData.push(td)
        if (goodsList.length === 1) td.isOnlyOneGoods = true
        for (let j = 0; j < goodsList.length; j++) {
          const goods = goodsList[j];
          const payProcessInfo = payProcessListMatch[j]
          if (j === 0) {
            resultData.push(buildMultiColumns(td, goods, payProcessInfo))
          } else {
            resultData.push(buildMultiColumns({orderCode: td.orderCode, isOnlyOneGoods: td.isOnlyOneGoods}, goods, payProcessInfo))
          }
        }

        // 如果支付路径数量大于货物，继续推
        if (payProcessListMatch.length > goodsList.length) {
          for (let z = goodsList.length; z < payProcessListMatch.length; z++) {
            const payProcessInfo = payProcessListMatch[z]
            resultData.push(buildMultiColumns({orderCode: td.orderCode, isOnlyOneGoods: td.isOnlyOneGoods}, undefined, payProcessInfo))
          }
        }
      }

      tableData.value = resultData
      pagination.total = res.data.data.total
    }
    pagination.setCallback(getTableData)

    onActivated(() => {
      getTableData()
    })

    onMounted(async () => {
      
      getAreaListOfGx(gxAreaList) /*已在函数内部添加子项*/
      
      const bpaListResp = await getPlatformBankAccountList({ pageNo: 1, pageSize: 999999 })
      const bpaList = bpaListResp.data.data.records.map(record => {
        return {
          label: record.bankAccountName,
          value: record.id
        }
      })
      copyObject(bpaInfoList, bpaList)
    })

    const spanMethod: SpanMethod = ({ row, column, rowIndex, columnIndex }) => {
      // 需要合并的列
      let columnsToSetRowSpan = [0, 1, 2, 3, 4, 5, 6, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]

      // 处理不同情况的列合并
      if (isExport.value) {
        // 打印状态下会少第一列，所以最后需要去掉最后一列
        columnsToSetRowSpan.pop()
        columnsToSetRowSpan.push(31)
      } else {
        columnsToSetRowSpan.push(27)
      }

      // 特殊处理：如果只有一种货物，也合并货物行
      if (row.isOnlyOneGoods) {
        const goodsRows = [7, 8, 9, 10, 11, 12]
        columnsToSetRowSpan = columnsToSetRowSpan.concat(goodsRows)
      }
      if (columnsToSetRowSpan.indexOf(columnIndex) >= 0) {
        if (rowIndex === 0 || row.orderCode != tableData.value[rowIndex - 1].orderCode) {
          // 如果这行的orderCode和上一行的不一样，则需要合并行
          const rowSpanNumber = tableData.value.filter(d => d.orderCode === row.orderCode).length
          return {
            rowspan: rowSpanNumber,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1
      }
    }

    const tableRowClassName = ({ row , rowIndex }: {row: AnyObject, rowIndex: number}) => {
      if (selectedTableData.value.find(sd => sd.orderid === row.orderid) != null)
        return 'invoice-success-row'
      return ''
    }

    const onSelectionChange = (selection: AnyArray) => selectedTableData.value = selection

    const exportRef = ref()
    const invoiceDetailRef = ref()
    const payProcessDetailRef = ref()
    const isExport = ref(false)

    const searchInputs = reactive<Inputs>([
      {
        label: '订单号',
        type: 'input',
        placeholder: '请输入订单号',
        clearable: true,
        model: toRef(searchData, 'orderNo')
      }, {
        label: '开票状态',
        type: 'select',
        placeholder: '开票状态',
        clearable: true,
        model: toRef(searchData, 'invoiceStatus'),
        width: '120px',
        options: [
          { value: 2, label: '已开票' },
          { value: 3, label: '申请中' }
        ]
      }, {
        label: '胶合板厂名',
        type: 'input',
        placeholder: '请输入胶合板厂名',
        clearable: true,
        width: '180px',
        model: toRef(searchData, 'jhbcName')
      }, {
        label: '生产机主名',
        type: 'input',
        placeholder: '请输入生产机主名',
        clearable: true,
        width: '180px',
        model: toRef(searchData, 'xqjzName')
      }, {
        label: '发票号',
        type: 'input',
        placeholder: '请输入发票号',
        clearable: true,
        width: '180px',
        model: toRef(searchData, 'invoiceCode')
      }, {
        label: '订单新建时间',
        type: 'group',
        divider: '-',
        inputs: [
          {
            type: 'date',
            placeholder: '请选择时间',
            clearable: true,
            width: '150px',
            model: toRef(searchData, 'applyStartTime')
          }, {
            type: 'date',
            placeholder: '请选择时间',
            clearable: true,
            width: '150px',
            model: toRef(searchData, 'applyEndTime')
          }
        ]
      }, {
        label: '区域',
        type: 'select',
        placeholder: '请选择区域',
        clearable: true,
        options: gxAreaList,
        model: toRef(searchData, 'areaCode')
      }, {
        label: '支付公司',
        type: 'select',
        multiple: true,
        options: bpaInfoList,
        model: toRef(searchData, 'bpaIds')
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '发票回填',
        icon: 'el-icon-plus',
        onClick: () => {
          if (selectedTableData.value.length === 0) {
            ElMessage.warning('请选择要回填的订单')
            return
          }
          for (let index = 0; index < selectedTableData.value.length; index++) {
            const selectedData = selectedTableData.value[index];
            if (selectedData.invoiceStatus === 2) {
              ElMessage.warning('订单' + selectedData.orderCode + '已开票，不能重复回填')
              return
            }
          }
          invoiceDetailRef.value.show(selectedTableData.value)
        }
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          isExport.value = true
          exportRef.value.doExport(() => isExport.value = false)
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        label: '多选',
        type: 'selection',
        width: '70px',
        align: 'center',
        reserveSelection: true,
      }, {
        prop: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
      }, {
        prop: 'orderCode',
        label: '订单号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'invoiceStatus',
        type: 'status',
        label: '开票状态',
        minWidth: '110px',
        align: 'center',
        statusType: [
          { value: 1, label: '未开票', type: 'info' },
          { value: 2, label: '已开票', type: 'success' },
          { value: 3, label: '申请中', type: 'default' }
        ]
      }, {
        prop: 'isInvoiceFirst',
        type: 'status',
        label: '先票后款',
        minWidth: '110px',
        align: 'center',
        statusType: [
          { value: 1, label: '是', type: 'default' },
          { value: 2, label: '否', type: 'warning' }
        ]
      }, {
        prop: 'xqjzName',
        label: '生产机主',
        minWidth: '200px'
      }, {
        prop: 'jhbcName',
        label: '胶合板厂',
        minWidth: '200px'
      }, {
        prop: 'goodsName',
        label: '货物名称',
        minWidth: '150px'
      }, {
        prop: 'unitPrice',
        label: '货物单价',
        minWidth: '100px'
      }, {
        prop: 'goodsAmount',
        label: '货物数量',
        minWidth: '100px'
      }, {
        prop: 'goodsUnit',
        label: '货物单位',
        minWidth: '120px'
      }, {
        prop: 'goodsTotalPrice',
        label: '货物总价(元)',
        minWidth: '130px'
      }, {
        prop: 'unitDesc',
        label: '货物描述',
        minWidth: '150px'
      }, {
        prop: 'paymentdays',
        label: '账期',
        minWidth: '80px',
        type: 'render',
        align: 'center',
        render: scope => {
          if (scope.row.paytype == 1) {
            return '现结'
          }else{
            return (scope.row.paymentdays + '天')
          }
        }
      }, {
        prop: 'totalprice',
        label: '订单总价(元)',
        minWidth: '150px'
      }, {
        prop: 'serviceFeeRate',
        label: '服务费率(%)',
        minWidth: '130px'
      }, {
        prop: 'serviceFee',
        label: '服务费(元)',
        minWidth: '100px'
      }, {
        prop: 'invoiceAmount',
        label: '开票金额(元)',
        minWidth: '120px',
        /* 2022年5月6日 不再做判断。
        type: 'render',
        render: scope => {
          const invoiceStatus = scope.row.invoiceStatus
          if (invoiceStatus === 1 || invoiceStatus === 3) {
            // 未开票或者开票中的，使用计算的金额（订单费+服务费）
            return scope.row.invoiceAmount
          }else{
            // 已开票，则使用开票回填的金额
            return scope.row.amountMoney
          }
        }*/
      }, {
        prop: 'applyTime',
        type: 'render',
        label: '开票申请时间',
        minWidth: '200px',
        render: scope => formatTime(scope.row.applyTime)
      }, {
        prop: 'invoiceCode',
        label: '发票号',
        minWidth: '200px',
      }, {
        prop: 'invoiceDate',
        type: 'render',
        label: '开票时间',
        minWidth: '150px',
        render: scope => formatDate(scope.row.invoiceDate)
      }, {
        prop: 'invoiceTitle',
        label: '开票抬头',
        minWidth: '150px'
      }, {
        prop: 'taxpayCode',
        label: '纳税人识别码',
        minWidth: '150px'
      }, {
        prop: 'invoiceAddress',
        label: '开票地址',
        minWidth: '300px'
      }, {
        prop: 'telephone',
        label: '开票电话',
        minWidth: '150px'
      }, {
        prop: 'bankName',
        label: '开户行',
        minWidth: '200px'
      }, {
        prop: 'accountNo',
        label: '银行账户',
        minWidth: '150px',
        preventFormat: true
      }, {
        show: () => isExport.value,
        prop: 'bankAccountName',
        label: '支付路径',
        minWidth: '200px'
      }, {
        show: () => isExport.value,
        prop: 'goodsFee',
        label: '订单总价',
        minWidth: '150px'
      }, {
        show: () => isExport.value,
        prop: 'serviceFee',
        label: '服务费',
        minWidth: '150px'
      }, {
        show: () => isExport.value,
        prop: 'invoiceAmount',
        label: '开票金额',
        minWidth: '150px'
      }, {
        label: '操作',
        type: 'button',
        fixed: 'right',
        buttons: [
          {
            label: '详情',
            onClick: async scope => {
              payProcessDetailRef.value.show(scope.row.orderid)
            }
          }
        ]
      }
    ])
    
    const mySummaryMethod = (scope: AnyObject) => {
      let props = ['goodsAmount', 'goodsTotalPrice', 'totalprice', 'serviceFee', 'invoiceAmount']
      let dataList = scope.data.filter((item: AnyObject) => !!item.orderid)
      return getTableColumnsSummary(props, scope.columns, dataList)
    }
    
    return {
      getTableData,
      onSelectionChange,
      invoiceDetailRef,
      payProcessDetailRef,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      badgeValues,
      tableData,
      UserRole,
      selectCount,
      exportRef,
      spanMethod,
      tableRowClassName,
      mySummaryMethod
    }
  }
})
